import React from "react"
import LayoutCourse from "../../../../../layouts/course"
import Seo from "../../../../../components/seo"
import Heading from "../../../../../components/heading"
import CourseFooterNext from "../../../../../components/course-footer-next"
import Stack from "../../../../../components/stack"
import Box from "../../../../../components/box"
import Paragraph from "../../../../../components/paragraph"
import Note from "../../../../../components/note"
import AudioPlayer from "../../../../../components/audio-player"
import MoodBarometer from "../../../../../components/mood-barometer"
import {
  useCourseStore,
  getCourseNavigation,
} from "../../../../../store/courses"

const Page = () => {
  const { setAnswer } = useCourseStore()
  const navigation = getCourseNavigation({ courseId: "jahreszeiten" })

  return (
    <LayoutCourse
      as="form"
      navigation={navigation}
      footer={
        <CourseFooterNext to="/kurse/jahreszeiten/01-jahreszeitengedicht/gedicht-sprechen" />
      }
    >
      <Seo title="Das Gedicht hören" />
      <Box>
        <Stack>
          <Heading as="h1" level={2}>
            Das Gedicht hören
          </Heading>
          <Paragraph>
            Gedichte haben ihren Ursprung in der Musik. In der Antike nannte man
            sie nach dem Musikinstrument, der Leier (griechisch lyra), das zu
            ihrem Vortrag gespielt wurde. Wie ein Lied hat auch ein Gedicht eine
            Melodie und einen Rhythmus. Das wird besonders deutlich, wenn man
            das Gedicht laut vorliest oder auswendig aufsagt. Je nachdem, wer
            das Gedicht spricht und wie es gesprochen wird, verändert es seinen
            Klang, seine Wirkung und manchmal sogar seine Bedeutung.
          </Paragraph>
          <Note variant="task">
            Hör dir die verschiedenen Versionen an und wähle aus, wie das
            Gehörte auf dich wirkt.
          </Note>
          <Stack space={6}>
            <AudioPlayer title="Variante 1" src={`kurse/fruehling-version-1`} />
            <MoodBarometer
              onUpdate={({ checkedIds }) => {
                setAnswer({
                  courseId: "jahreszeiten",
                  chapterId: "01-jahreszeitengedicht",
                  taskId: "gedicht-hoeren",
                  answer: checkedIds,
                })
              }}
              contents={[
                {
                  id: "froehlich",
                  title: "fröhlich",
                  mood: "😊",
                },
                {
                  id: "traurig",
                  title: "traurig",
                  mood: "😢",
                },
                {
                  id: "ruhig",
                  title: "ruhig",
                  mood: "🤫",
                },
                {
                  id: "langsam",
                  title: "langsam",
                  mood: "🐢",
                },
                {
                  id: "schnell",
                  title: "schnell",
                  mood: "🏎",
                },
                {
                  id: "regelmaessig",
                  title: "regelmäßig",
                  mood: "🚣",
                },
                {
                  id: "lebendig",
                  title: "lebendig",
                  mood: "🐬",
                },
                {
                  id: "harmonisch",
                  title: "harmonisch",
                  mood: "🎻",
                },
                {
                  id: "hell",
                  title: "hell",
                  mood: "💡",
                },
                {
                  id: "dunkel",
                  title: "dunkel",
                  mood: "🌒",
                },
                {
                  id: "aengstlich",
                  title: "ängstlich",
                  mood: "🐰",
                },
                {
                  id: "schrill",
                  title: "schrill",
                  mood: "🦄",
                },
                {
                  id: "merkwuerdig",
                  title: "merkwürdig",
                  mood: "🥴",
                },
                {
                  id: "gefaehrlich",
                  title: "gefährlich",
                  mood: "🌩",
                },
              ]}
            />
          </Stack>
          <Stack space={6}>
            <AudioPlayer title="Variante 2" src={`kurse/fruehling-version-2`} />
            <MoodBarometer
              onUpdate={({ checkedIds }) => {
                setAnswer({
                  courseId: "jahreszeiten",
                  chapterId: "01-jahreszeitengedicht",
                  taskId: "gedicht-hoeren",
                  answer: checkedIds,
                })
              }}
              contents={[
                {
                  id: "froehlich",
                  title: "fröhlich",
                  mood: "😊",
                },
                {
                  id: "traurig",
                  title: "traurig",
                  mood: "😢",
                },
                {
                  id: "ruhig",
                  title: "ruhig",
                  mood: "🤫",
                },
                {
                  id: "langsam",
                  title: "langsam",
                  mood: "🐢",
                },
                {
                  id: "schnell",
                  title: "schnell",
                  mood: "🏎",
                },
                {
                  id: "regelmaessig",
                  title: "regelmäßig",
                  mood: "🚣🏾‍♂",
                },
                {
                  id: "lebendig",
                  title: "lebendig",
                  mood: "🐬",
                },
                {
                  id: "harmonisch",
                  title: "harmonisch",
                  mood: "🎻",
                },
                {
                  id: "hell",
                  title: "hell",
                  mood: "💡",
                },
                {
                  id: "dunkel",
                  title: "dunkel",
                  mood: "🌒",
                },
                {
                  id: "aengstlich",
                  title: "ängstlich",
                  mood: "🐰",
                },
                {
                  id: "schrill",
                  title: "schrill",
                  mood: "🦄",
                },
                {
                  id: "merkwuerdig",
                  title: "merkwürdig",
                  mood: "🥴",
                },
                {
                  id: "gefaehrlich",
                  title: "gefährlich",
                  mood: "🌩",
                },
              ]}
            />
          </Stack>
          <Paragraph>Welche Version gefällt dir am besten?</Paragraph>
        </Stack>
      </Box>
    </LayoutCourse>
  )
}

export default Page
